'use client';

import { useRouter } from 'next/navigation';
import { isHttpReg } from '../util/reg';
import { SOLUTION_TYPE } from '../util/solution';
import { URL_PATH } from '../util/url.utils';
interface IHandleUrlClickProps {
  url?: string;
  isExternal: boolean;
  isPreview?: boolean;
}

const useUrlRouter = () => {
  const router = useRouter();

  const handleUrlClick = ({ url, isExternal, isPreview = false }: IHandleUrlClickProps) => {
    if (!url) return;

    if (isExternal) {
      return window.open(isHttpReg(url) ? url : `https://${url}`, '_blank');
    }

    router.push(isPreview ? url : URL_PATH[SOLUTION_TYPE](url));
  };

  return { handleUrlClick, router };
};

export default useUrlRouter;
