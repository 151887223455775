import classnames from 'classnames/bind';
import style from './ViewFooterLink.module.scss';
import React, { FC, useState } from 'react';
import { IFooterLink, IPrivacyPolicy, IPrivacyPolicyItem } from 'ui/interface/footer/IFooterLink';
import { LINK_TYPE } from '../../interface/common/Link';
import useUrlRouter from '../../hook/useUrlRouter';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { FOOTER_MENU, FooterMenuOptionType } from '../../constants/footer.constant';
import DeclineEmailModal from './DeclineEmailModal';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import { UseQueryResult } from '@tanstack/react-query';
import { ILNBType, ISingleLNBType, URL_TYPE } from '../../interface/header/IMenu';
import { HasOption } from '../../interface/common/hasOption';
import { LANGUAGE } from '../../constants/language';
import { useUrl } from '../../hook/useUrl';

const cx = classnames.bind(style);

interface IViewFooterLinkProps {
  colorType: 'WHITE' | 'BLACK';
  isPreview?: boolean;
  footerLinkList?: IFooterLink[];
  policyList?: IPrivacyPolicy[];
  querySelectedPolicy?: (policySn: string, endabled: boolean) => UseQueryResult<IPrivacyPolicyItem, unknown>;
}

const ViewFooterLink: FC<IViewFooterLinkProps & HasOption> = ({
  colorType,
  isPreview = false,
  footerLinkList,
  policyList,
  querySelectedPolicy,
  options,
}) => {
  const { handleUrlClick } = useUrlRouter();
  const { buildUrl } = useUrl();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const [footerModal, setFooterModal] = useState<null | Exclude<FooterMenuOptionType, typeof FOOTER_MENU.NORMAL>>(null);
  const languageType = options && options['languageType'];

  const getLinkEls = () => {
    return footerLinkList?.map((link, index) => {
      const { menuType, title, boldType, highlightStatus, url, linkType, pageSn, pageUrl } = link;

      const handleClick: Record<FooterMenuOptionType, () => void> = {
        [FOOTER_MENU.NORMAL]: () => {
          if (linkType === LINK_TYPE.PAGE && !pageSn) return;
          if (linkType === LINK_TYPE.URL && !url) return;

          switch (linkType) {
            case LINK_TYPE.PAGE:
              if (!pageUrl) return;
              return handleUrlClick({
                url:
                  pageUrl.urlType === URL_TYPE.EXTERNAL || !isPreview
                    ? pageUrl.url
                    : buildUrl('/editor/preview')
                        .withSearchParam('designSn', searchParams.get('designSn'))
                        .withSearchParam('pageSn', link.pageSn)
                        .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                        .getResult(),
                isExternal: pageUrl.urlType === URL_TYPE.EXTERNAL,
                isPreview,
              });
            case LINK_TYPE.URL:
              return handleUrlClick({ url, isExternal: true, isPreview });
            default:
              return;
          }
        },
        [FOOTER_MENU.PRIVACY_POLICY]: () => {
          setFooterModal(FOOTER_MENU.PRIVACY_POLICY);
        },
        [FOOTER_MENU.EMAIL_CRAWLING_REJECT]: () => {
          setFooterModal(FOOTER_MENU.EMAIL_CRAWLING_REJECT);
        },
      };

      return (
        <div
          key={index}
          className={cx(
            'link',
            { darkMode: colorType === 'BLACK' },
            { em: boldType === 'ON' },
            { highlight: highlightStatus === 'ON' }
          )}
        >
          <div className={cx('link-text')} onClick={handleClick[menuType]}>
            {title}
          </div>
          {(footerLinkList ?? []).length !== index + 1 && <div className={cx('divider')} />}
        </div>
      );
    });
  };

  const close = () => setFooterModal(null);

  const getModalEl = () => {
    if (!footerModal) return;
    if (footerModal === FOOTER_MENU.EMAIL_CRAWLING_REJECT)
      return <DeclineEmailModal show={true} close={close} confirm={close} />;
    if (footerModal === FOOTER_MENU.PRIVACY_POLICY)
      return (
        <PrivacyPolicyModal
          show={true}
          close={close}
          // @ts-ignore
          querySelectedPolicy={querySelectedPolicy}
          policyList={policyList ?? []}
          confirm={close}
        />
      );

    return <></>;
  };

  return (
    <div className={cx('linkArea')}>
      {getLinkEls()}
      {getModalEl()}
    </div>
  );
};

export default ViewFooterLink;
