'use client';
import { FC, PropsWithChildren, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface IProps {
  domId: string;
}

// @ts-ignore
const Portal: FC<PropsWithChildren<IProps>> = ({ domId, children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // @ts-ignore
  return mounted ? createPortal(children, document.querySelector(`#${domId}`) as Element) : null;
};

export default Portal;
